<template>
<div>

  <div class="table-page-search-wrapper">
      <a-form layout="inline" class="formInquire">
        <a-row :gutter="48" class="orderTop">
          <a-col :md="7" :sm="12">
            <a-form-item label="始发地">
                <a-cascader
                  v-model="queryParam.fromGeoCode"
                  placeholder="始发地"                  
                  :options="geoCascaderList"
                />             
            </a-form-item>
          </a-col>

          <a-col :md="7" :sm="12">
            <a-form-item label="到达地">
                <a-cascader
                  v-model="queryParam.toGeoCode"
                  placeholder="到达地"                
                  :options="geoCascaderList"
                />       
            </a-form-item>
          </a-col>

         
        <a-col :md="7" :sm="12">
            <a-form-item label="分拨中心">
              <a-select  v-model="queryParam.toAllocateId"    placeholder="分拨中心" style="width: 80%">                             
                <a-select-option :key="item.id" v-for="item in allocateList" :value="item.id">
                  {{ item.allocateName }}
                </a-select-option>
              </a-select>
            </a-form-item>
        </a-col>
         
        </a-row>
      
        <a-row :gutter="48">
            <a-col>
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="$refs.pageTable.refresh(true)">查询</a-button>
              <a-button style="margin-left: 10px" @click="() => (queryParam = {})">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>


  <div class="table-operator" style="margin-bottom:15px">
    <div>
    <a-upload name="file" :beforeUpload="beforeUpload" :showUploadList="false" accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" style="margin-right:15px;display:inline-block;">
      <a-button type="primary" icon="upload">上传一级运费表</a-button>
    </a-upload>
    <a-button icon="download" @click="downloadTemplate()">下载表头</a-button>
    </div>
    <a-button type="primary" icon="download" @click="downloadExcel()" style="float:right">下载一级运费表</a-button>
  </div>

  <s-table ref="pageTable"    
    :rowKey="(record) => record.id"
    :columns="columns"
    :data="dataset"
    :pagination="pagination"
    :pageSize="10"
    :scroll="{x: 'max-content'}"
  >
    <a slot="name" slot-scope="text">{{ text }}</a>
    <span slot="operation" slot-scope="text, record">
      <a-popconfirm title="您确认要删除此条记录吗？" ok-text="确认" cancel-text="取消" @confirm="handleRemove(record)">
        <a>删除</a>
      </a-popconfirm>
    </span>
  </s-table>

  <a-modal
    key="upload"
    title="上传报价表"
    :width="900"
    :footer="null"
    :centered="true"
    :keyboard="false"
    :maskClosable="false"
    :visible="modalUploadVisible"
    @cancel="modalUploadCancel"
  >
    <a-table ref="modalTable" size="middle"
      :rowKey="(record) => record.idx"
      :columns="columns"
      :data-source="modalUploadDataset"
      :scroll="{x: 'max-content'}"
    >
      <a slot="name" slot-scope="text">{{ text }}</a>
    </a-table>
    <a-button style="margin:20px 0 0 92%" type="primary" htmlType="submit" :loading="buttonLoading.importSave" @click="modalUploadSubmit()">保存</a-button>
  </a-modal>

</div>
</template>
<script>
import { STable } from '@/components'
import { transportFirstList, transportFirstInsert, transportFirstRemove, documentInfo, geoCascaderList, geoAllocateList } from '@/api/quotation'
import { ossFileUrl } from '@/utils/upload'
import XLSX from 'xlsx'

const columns = [
  {
    key: 'fromCityName',
    dataIndex: 'fromCityName',
    title: '始发地',
    fixed: 'left',
    align: 'center',
    width: 100 ,
  },
  {
    key: 'toCityName',
    dataIndex: 'toCityName',
    title: '到达地',
    align: 'center',
    width: 100 ,
    fixed: 'left',
  },
  {
    key: 'toAllocateName',
    dataIndex: 'toAllocateName',
    title: '省公司分拨中心',
    align: 'center',
    fixed: 'left',
  },
  {
    key: 'range1FirstWeightPrice',
    dataIndex: 'range1FirstWeightPrice',
    title: '首重10KG',
    align: 'center',
  },
  {
    key: 'range1ContinuedWeightPrice',
    dataIndex: 'range1ContinuedWeightPrice',
    align: 'center',
    title: '超10KG续重（元/KG）',
  },
  {
    key: 'range2FirstWeightPrice',
    dataIndex: 'range2FirstWeightPrice',
    align: 'center',
    title: '首重100KG',
  },
  {
    key: 'range2ContinuedWeightPrice',
    dataIndex: 'range2ContinuedWeightPrice',
    title: '超100KG续重（元/KG）',
    align: 'center',
  },
  {
    key: 'range3FirstWeightPrice',
    dataIndex: 'range3FirstWeightPrice',
    title: '首重300KG',
    align: 'center',
  },
  {
    key: 'range3ContinuedWeightPrice',
    dataIndex: 'range3ContinuedWeightPrice',
    title: '超300KG续重（元/KG）',
    align: 'center',
  },
  {
    key: 'range4FirstWeightPrice',
    dataIndex: 'range4FirstWeightPrice',
    title: '首重500KG',
    align: 'center',
  },
  {
    key: 'range4ContinuedWeightPrice',
    dataIndex: 'range4ContinuedWeightPrice',
    title: '超500KG续重（元/KG）',
    align: 'center',
  },
  {
    key: 'range5FirstWeightPrice',
    dataIndex: 'range5FirstWeightPrice',
    title: '首重1200KG',
    align: 'center',
  },
  {
    key: 'range5ContinuedWeightPrice',
    dataIndex: 'range5ContinuedWeightPrice',
    title: '超1200KG续重（元/KG）',
    align: 'center',
  },
  {
    key: 'operation',
    dataIndex: 'operation',
    title: '操作',
    fixed: 'right',
    align: 'center',
    width: '100px',
    scopedSlots: {
      customRender: 'operation',
    },
  },
];

const excelConfig = {
  "fromCityName": "始发地",
  "toCityName": "到达地",
  "toAllocateName": "省公司分拨中心",
  "range1FirstWeightPrice": "首重10KG",
  "range1ContinuedWeightPrice": "超10KG续重（元/KG）",
  "range2FirstWeightPrice": "首重100KG",
  "range2ContinuedWeightPrice": "超100KG续重（元/KG）",
  "range3FirstWeightPrice": "首重300KG",
  "range3ContinuedWeightPrice": "超300KG续重（元/KG）",
  "range4FirstWeightPrice": "首重500KG",
  "range4ContinuedWeightPrice": "超500KG续重（元/KG）",
  "range5FirstWeightPrice": "首重1200KG",
  "range5ContinuedWeightPrice": "超1200KG续重（元/KG）",
};

export default {
  name: 'TableList',
  components: {
    STable,
    XLSX,
  },
  data() {
    return {
      columns: columns,
      dataset: parameter => {
        console.log('queryParam',this.queryParam)
        
        if(this.queryParam.fromGeoCode != undefined){
          this.queryParam.fromCityCode = this.queryParam.fromGeoCode[1]
        }

        if(this.queryParam.toGeoCode != undefined){
          this.queryParam.toCityCode = this.queryParam.toGeoCode[1]
        }
                
      return transportFirstList(Object.assign(parameter, this.queryParam))
          .then(res => {
            if (0 == res.code) {
              return res.result;
            }
          }).catch(e => {
            console.info(e);
            this.$message.error('数据加载失败，请稍后重试');
          });
      },
      cityData:[],
      pagination: { pageSizeOptions: ['10', '20', '50'] },
      queryParam: {},
      modalUploadVisible: false,
      modalUploadDataset: [],
      geoCascaderList: [],
      downloadExcelState: 0,
      downloadExcelDataset: [],
      allocateList:[],
      buttonLoading: {
        importSave: false,
      },
      fileUrl: '',
    };
  },
  mounted() {
    const parameter = {};
    geoCascaderList(parameter)
      .then(res => {
        if (0 == res.code) {
          this.geoCascaderList = res.result;
          this.getCityList(this.geoCascaderList)
          console.log('geoCascaderList',geoCascaderList)
        }
      }).catch(e => {
        console.info(e)
      }),

      geoAllocateList(parameter)
        .then(res => {

            this.allocateList = res.result                    
        }).catch(e => {
          console.info(e);          
      })   
     
  },
  methods: {
    beforeUpload(file){
      let that = this;
      let reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = (e) => {
          let workbook = XLSX.read(e.target.result, {type: 'binary'});
          let worksheet = workbook.Sheets[workbook.SheetNames[0]];
          let result = XLSX.utils.sheet_to_json(worksheet);
          console.log(["xlxs-sheet", result])
          let dateset = [];
          for (let i = 0; i < result.length; i++) {
            let row = {"idx": i};
            let importState = true
            for (let key in excelConfig) {
              let name = excelConfig[key];
              let value = result[i][name];
              if (key == "fromCityName") {
                let geoInfo = this.geoSearchByName(value);
                if (geoInfo == null) {
                  importState = false;
                  console.log(["xlxs-break", key, value])
                  break;
                }
                row["fromProvinceCode"] = geoInfo.provinceCode;
                row["fromProvinceName"] = geoInfo.provinceName;
                row["fromCityCode"] = geoInfo.cityCode;
                row["fromCityName"] = geoInfo.cityName;
              }
              else if (key == "toCityName") {
                let geoInfo = this.geoSearchByName(value);
                if (geoInfo == null) {
                  importState = false;
                  console.log(["xlxs-break", key, value])
                  break;
                }
                row["toProvinceCode"] = geoInfo.provinceCode;
                row["toProvinceName"] = geoInfo.provinceName;
                row["toCityCode"] = geoInfo.cityCode;
                row["toCityName"] = geoInfo.cityName;
              }
              else {
                row[key] = value;
              }
              if (typeof(value) == 'undefined' || (typeof(value) == 'string' && value.trim() == "")) {
                importState = false;
                console.log(["xlxs-break", key, value])
                break;
              }
            }
            if (importState && Object.keys(row).length > 1) {
              dateset.push(row);
            }
          }
          that.modalUploadDataset = dateset;
          that.modalUploadLoad();
      };
      return false;
    },
    
    geoSearchByName(name){
      for (let i = 0; i < this.geoCascaderList.length; i++) {
        let provinceInfo = this.geoCascaderList[i];
        let cityList = provinceInfo.children;
        for (let j = 0; j < cityList.length; j++) {
          let cityInfo = cityList[j];
          if (cityInfo.label.indexOf(name) !== -1) {
            return {
              "provinceCode": provinceInfo.value,
              "provinceName": provinceInfo.label,
              "cityCode": cityInfo.value,
              "cityName": cityInfo.label,
            }
          }
        }
      }
      return null;
    },
    modalUploadLoad(){
      this.modalUploadVisible = true;
    },
    modalUploadCancel(){
      this.modalUploadVisible = false;
    },
    modalUploadSubmit(e){
      let parameter = {"recordList": this.modalUploadDataset};
      this.buttonLoading.importSave = true;
      transportFirstInsert(parameter)
        .then(res => {
          if (0 == res.code) {
            this.$message.success('保存成功');
            this.modalUploadVisible = false;
            this.$refs.pageTable.refresh(true);
          } else {
            this.$message.error('保存失败');
          }
          this.buttonLoading.importSave = false;
        }).catch(e => {
          console.info(e);
          this.buttonLoading.importSave = false;
          this.$message.error('保存失败，请稍后重试');
        })
    },
   
    handleRemove(record){
      transportFirstRemove(record)
        .then(res => {
          if (0 == res.code) {
            this.$message.success('删除成功');
            this.$refs.pageTable.refresh(true);
          } else {
            this.$message.error('删除失败');
          }
        }).catch(e => {
          console.info(e);
          this.$message.error('删除失败，请稍后重试');
        })
    },
    downloadTemplate(){
      let parameter = {"type": 101};
      documentInfo(parameter)
        .then(res => {
          if (0 == res.code) {
            window.location.href = ossFileUrl(res.result.document);
          } else {
            this.$message.error('下载失败');
          }
        }).catch(e => {
          console.info(e);
          this.$message.error('下载失败，请稍后重试');
        });
    },
    downloadExcel(){
      if (this.downloadExcelState > 0) {
        return;
      }
      this.downloadExcelState = 1;
      this.downloadExcelDataset = [];

      let downloadExcelTitle = [];
      for (let key in excelConfig) {
        downloadExcelTitle.push(excelConfig[key]);
      }
      this.downloadExcelDataset.push(downloadExcelTitle);

      let parameter = {pageNo: 1, pageSize: 100};
      this.downloadDataset(parameter);
    },
    
    downloadDataset(parameter) {
      let that = this;
      transportFirstList(Object.assign(parameter, this.queryParam))
        .then(res => {
          if (0 == res.code) {
            console.log(res.result);
            const result = res.result.result;
            for (let i = 0; i < result.length; i++) {
              const dataset = [
                result[i].fromCityName,
                result[i].toCityName,
                result[i].toAllocateName,
                result[i].range1FirstWeightPrice,
                result[i].range1ContinuedWeightPrice,
                result[i].range2FirstWeightPrice,
                result[i].range2ContinuedWeightPrice,
                result[i].range3FirstWeightPrice,
                result[i].range3ContinuedWeightPrice,
                result[i].range4FirstWeightPrice,
                result[i].range4ContinuedWeightPrice,
                result[i].range5FirstWeightPrice,
                result[i].range5ContinuedWeightPrice,
              ];
              that.downloadExcelDataset.push(dataset);
            }
            const pageNo = res.result.pageNo;
            const pageSize = res.result.pageSize;
            const totalCount = res.result.totalCount;
            const totalPage = Math.ceil(totalCount / pageSize);
            if (pageNo >= totalPage) {
              let sheet = XLSX.utils.aoa_to_sheet(that.downloadExcelDataset);
              let book = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(book, sheet, '一级运费表');
              XLSX.writeFile(book, '一级运费表.xls');
              that.downloadExcelState = 0
              return;
            }
            parameter.pageNo = pageNo + 1;
            that.downloadDataset(parameter);
          }
        }).catch(e => {
          console.info(e);
          that.downloadExcelState = 0
          that.$message.error('下载失败，请稍后重试');
        });
    }
  },
};
</script>
<style lang="less" scoped>
/deep/ .ant-table-tbody{
  background-color: #fff;
}
/deep/ .ant-form-item-label{
  text-align: left !important;
}
 .ant-modal-body{
  overflow: hidden;
}
.table-operator{
  display: flex;
  justify-content: space-between;
  >div{
    display: flex;
     >span:nth-child(1){
      display: flex !important;
      /deep/ .ant-upload-list-text {
      background-color: #fff;
      margin-left: 10px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      .ant-upload-list-item{
        margin-top: 0 !important;
        }
      }
   }
  }
}
/deep/ .ant-table-pagination {
 > li{
  background-color: #fff !important;
  min-width: 35px !important;
  height: 35px !important;
  line-height: 35px !important;
  border-radius: 4px;
  box-sizing: border-box;
  >div{
    margin: 0 0;
    >div{
      border: none;
    }
   }
  }
  .ant-pagination-options{
   border:1px solid #d9d9d9;
  .ant-select-selection--single {
    height: 32px;
    .ant-select-selection__rendered{
      line-height: 35px;
    }
  }
  }
  .ant-pagination-item-active{
    border-color: #1890ff !important;
  }
}
.table-operator{
  /deep/  .ant-upload-list-item{
    margin-top: 5px !important;
    .ant-upload-list-item-info .ant-upload-list-item-name{
      padding-right:10px;
    }
  }
  /deep/ .anticon-close{
    font-size: 15px !important;
  }
}
</style>
