import { axios } from '@/utils/request'

const url = {
  arteryList: '/service_provider/quotationArtery/findPage',
  arteryInsert: '/service_provider/quotationArtery/insertRecord',
  arteryUpdate: '/service_provider/quotationArtery/updateRecord',
  arteryRemove: '/service_provider/quotationArtery/removeRecord',
  costInfo: '/service_provider/quotationCost/findOne',
  costInsert: '/service_provider/quotationCost/insertRecord',
  costUpdate: '/service_provider/quotationCost/updateRecord',
  customerPriceList: '/service_provider/quotationCustomerPrice/findPage',
  customerPriceInsert: '/service_provider/quotationCustomerPrice/insertRecord',
  customerPriceUpdate: '/service_provider/quotationCustomerPrice/updateRecord',
  customerPriceUpdateQrcode: '/service_provider/quotationCustomerPrice/updateQrcode',
  customerPriceRemove: '/service_provider/quotationCustomerPrice/removeRecord',
  customPriceRouteList: '/service_provider/quotationCustomPriceRoute/findPage',
  customPriceRouteRemove: '/service_provider/quotationCustomPriceRoute/removeRecord',
  dispatchList: '/service_provider/quotationDispatch/findPage',
  dispatchCityUniqueList: '/service_provider/quotationDispatch/findCityUniqueList',
  dispatchInsert: '/service_provider/quotationDispatch/insertRecord',
  dispatchRemove: '/service_provider/quotationDispatch/removeRecord',
  fixedPriceRouteList: '/service_provider/quotationFixedPriceRoute/findPage',
  fixedPriceRouteInsert: '/service_provider/quotationFixedPriceRoute/insertRecord',
  fixedPriceRouteRemove: '/service_provider/quotationFixedPriceRoute/removeRecord',
  fixedPriceList: '/service_provider/quotationFixedPrice/findPage',
  fixedPriceInsert: '/service_provider/quotationFixedPrice/insertRecord',
  fixedPriceUpdate: '/service_provider/quotationFixedPrice/updateRecord',
  fixedPriceRemove: '/service_provider/quotationFixedPrice/removeRecord',
  pickupList: '/service_provider/quotationPickup/findPage',
  pickupInsert: '/service_provider/quotationPickup/insertRecord',
  pickupUpdate: '/service_provider/quotationPickup/updateRecord',
  pickupRemove: '/service_provider/quotationPickup/removeRecord',
  profitInfo: '/service_provider/quotationProfit/findOne',
  profitInsert: '/service_provider/quotationProfit/insertRecord',
  profitUpdate: '/service_provider/quotationProfit/updateRecord',
  transportFirstList: '/service_provider/quotationTransportFirst/findPage',
  transportFirstInsert: '/service_provider/quotationTransportFirst/insertRecord',
  transportFirstRemove: '/service_provider/quotationTransportFirst/removeRecord',
  transportSecondList: '/service_provider/quotationTransportSecond/findPage',
  transportSecondInsert: '/service_provider/quotationTransportSecond/insertRecord',
  transportSecondRemove: '/service_provider/quotationTransportSecond/removeRecord',
  extTextInfo: '/service_provider/quotationExtText/findOne',
  extTextInsert: '/service_provider/quotationExtText/insertRecord',
  extTextUpdate: '/service_provider/quotationExtText/updateRecord',
  extChargeList: '/service_provider/quotationExtCharge/findPage',
  extChargeInsert: '/service_provider/quotationExtCharge/insertRecord',
  extChargeUpdate: '/service_provider/quotationExtCharge/updateRecord',
  extChargeRemove: '/service_provider/quotationExtCharge/removeRecord',
  documentInfo: '/service_provider/quotationDocument/findOne',
  scatteredPreview: '/service_provider/quotationPreview/fixedPriceQuotation',
  lowestCharge: '/service_provider/quotationExtCharge/findOne',
  sectionPrice: '/service_provider/quotationPreview/subsectionsQuotation',
  geoCascaderList: '/service_provider/geoCascader/findList',
  geoAllocateList: '/service_provider/quotationAllocate/findList',
}
const method = {
  get: 'get',
  post: 'post',
};
const header = {
  json: { 'Content-Type': 'application/json;charset=UTF-8' }
};

export default url

export function arteryList(parameter) {
  return axios({
    url: url.arteryList,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function arteryInsert(parameter) {
  return axios({
    url: url.arteryInsert,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function arteryUpdate(parameter) {
  return axios({
    url: url.arteryUpdate,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function arteryRemove(parameter) {
  return axios({
    url: url.arteryRemove,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function costInfo(parameter) {
  return axios({
    url: url.costInfo,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function costInsert(parameter) {
  return axios({
    url: url.costInsert,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function costUpdate(parameter) {
  return axios({
    url: url.costUpdate,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function customerPriceList(parameter) {
  return axios({
    url: url.customerPriceList,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function customerPriceInsert(parameter) {
  return axios({
    url: url.customerPriceInsert,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function customerPriceUpdate(parameter) {
  return axios({
    url: url.customerPriceUpdate,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function customerPriceUpdateQrcode(parameter) {
  return axios({
    url: url.customerPriceUpdateQrcode,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function customerPriceRemove(parameter) {
  return axios({
    url: url.customerPriceRemove,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function customPriceRouteList(parameter) {
  return axios({
    url: url.customPriceRouteList,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function customPriceRouteRemove(parameter) {
  return axios({
    url: url.customPriceRouteRemove,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function dispatchList(parameter) {
  return axios({
    url: url.dispatchList,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function dispatchCityUniqueList(parameter) {
  return axios({
    url: url.dispatchCityUniqueList,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function dispatchInsert(parameter) {
  return axios({
    url: url.dispatchInsert,
    method: method.post,
    headers: header.json,
    timeout: 600000,
    data: parameter
  })
}

export function dispatchRemove(parameter) {
  return axios({
    url: url.dispatchRemove,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function fixedPriceRouteList(parameter) {
  return axios({
    url: url.fixedPriceRouteList,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function fixedPriceRouteInsert(parameter) {
  return axios({
    url: url.fixedPriceRouteInsert,
    method: method.post,
    headers: header.json,
    timeout: 600000,
    data: parameter
  })
}

export function fixedPriceRouteRemove(parameter) {
  return axios({
    url: url.fixedPriceRouteRemove,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function fixedPriceList(parameter) {
  return axios({
    url: url.fixedPriceList,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function fixedPriceInsert(parameter) {
  return axios({
    url: url.fixedPriceInsert,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function fixedPriceUpdate(parameter) {
  return axios({
    url: url.fixedPriceUpdate,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function fixedPriceRemove(parameter) {
  return axios({
    url: url.fixedPriceRemove,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function pickupList(parameter) {
  return axios({
    url: url.pickupList,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function pickupInsert(parameter) {
  return axios({
    url: url.pickupInsert,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function pickupUpdate(parameter) {
  return axios({
    url: url.pickupUpdate,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function pickupRemove(parameter) {
  return axios({
    url: url.pickupRemove,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function profitInfo(parameter) {
  return axios({
    url: url.profitInfo,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function profitInsert(parameter) {
  return axios({
    url: url.profitInsert,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function profitUpdate(parameter) {
  return axios({
    url: url.profitUpdate,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function transportFirstList(parameter) {
  return axios({
    url: url.transportFirstList,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function transportFirstInsert(parameter) {
  return axios({
    url: url.transportFirstInsert,
    method: method.post,
    headers: header.json,
    timeout: 600000,
    data: parameter
  })
}

export function transportFirstRemove(parameter) {
  return axios({
    url: url.transportFirstRemove,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function transportSecondList(parameter) {
  return axios({
    url: url.transportSecondList,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function transportSecondInsert(parameter) {
  return axios({
    url: url.transportSecondInsert,
    method: method.post,
    headers: header.json,
    timeout: 600000,
    data: parameter
  })
}

export function transportSecondRemove(parameter) {
  return axios({
    url: url.transportSecondRemove,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function extTextInfo(parameter) {
  return axios({
    url: url.extTextInfo,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function extTextInsert(parameter) {
  return axios({
    url: url.extTextInsert,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function extTextUpdate(parameter) {
  return axios({
    url: url.extTextUpdate,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function extChargeList(parameter) {
  return axios({
    url: url.extChargeList,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function extChargeInsert(parameter) {
  return axios({
    url: url.extChargeInsert,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function extChargeUpdate(parameter) {
  return axios({
    url: url.extChargeUpdate,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function extChargeRemove(parameter) {
  return axios({
    url: url.extChargeRemove,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function documentInfo(parameter) {
  return axios({
    url: url.documentInfo,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function scatteredPreview(parameter) {
  return axios({
    url: url.scatteredPreview,
    method: method.post,
    headers: header.json,
    timeout: 600000,
    data: parameter
  })
}
export function sectionPrice(parameter) {
  return axios({
    url: url.sectionPrice,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function geoCascaderList(parameter) {
  return axios({
    url: url.geoCascaderList,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function geoAllocateList(parameter) {
  return axios({
    url: url.geoAllocateList,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}

export function lowestCharge(parameter) {
  return axios({
    url: url.lowestCharge,
    method: method.post,
    headers: header.json,
    data: parameter
  })
}


